export default class RecaptchaV3 {
  siteKey = null;

  // Looks for contact forms and adds the appropriate `onsubmit` action to the form.
  init(options) {
    this.siteKey = options.siteKey;
    this.hideBadgeIfDisclaimerPresent();
    this.addHandlerToForms();
  }

  addHandlerToForms() {
    const formElementsSelector = [
      'form[action*="/contact"] input[name="form_type"][value="contact"]',
      'form[action*="/lead"] input[name="form_type"][value="lead"]',
    ].join(", ");
    const formElements = document.querySelectorAll(formElementsSelector);
    formElements.forEach(element => {
      const form = element.form;
      const action = element.getAttribute("value");
      form.setAttribute("onsubmit", `window.Realty120.RecaptchaV3.addTokenToForm(this, '${action}'); return false;`);
    })
  }

  hideBadgeIfDisclaimerPresent() {
    if (document.querySelector("span[data-recaptcha-disclaimer]")) {
      const styleElement = document.createElement("style");
      document.head.appendChild(styleElement);
      styleElement.sheet.insertRule(".grecaptcha-badge { visibility: hidden; }");
    }
  }

  // Intercepts form submission to request and add recaptcha token to the form before submitting.
  addTokenToForm(form, action) {
    grecaptcha
    .execute(this.siteKey, { action })
    .then(token => {
      let token_field = form.querySelector("input[name=recaptcha_v3_token]");
      if (token_field instanceof HTMLElement) {
        token_field.setAttribute("value", token);
      } else {
        token_field = document.createElement("input");
        token_field.setAttribute("name", "recaptcha_v3_token");
        token_field.setAttribute("type", "hidden");
        token_field.setAttribute("value", token);
        form.appendChild(token_field, form)
      }
      form.submit();
    })
  }
}
